import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const PopupWelcome = ({ club_nick }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hidePopup, setHidePopup] = useState(false);

  useEffect(() => {
    const welcomeMessage = localStorage.getItem(
      `welcome_${club_nick.toUpperCase()}`
    );
    if (welcomeMessage) {
      setDialogOpen(true);
    }
  }, [club_nick]);

  const handleClose = () => {
    if (hidePopup) {
      localStorage.removeItem(`welcome_${club_nick.toUpperCase()}`);
    }
    setDialogOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setHidePopup(event.target.checked);
  };

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>환영합니다!</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            {club_nick.toUpperCase()}에 오신 걸 환영합니다. <br />
            가입하신 분들 모두 아래 공지방 클릭 후 입장 부탁드립니다. <br />
            참여코드는 <strong>yrc1004</strong> 입니다.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <a
              href="https://open.kakao.com/o/gvOlNToc"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#3f50b5", textDecoration: "inherit" }}
            >
              <strong>
                카카오톡 오픈채팅 {club_nick.toUpperCase()} 공지방
              </strong>
            </a>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox checked={hidePopup} onChange={handleCheckboxChange} />
            }
            label="이 창을 숨기기"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopupWelcome;
