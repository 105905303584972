import React from "react";
import conf from "../../config/conf_sql.json";

const extractBaseUrl = (url) => {
  const parts = url.split("/");
  if (parts.length > 2) {
    return parts.slice(0, 3).join("/");
  }
  return url; // URL이 기대한 형식이 아닐 경우 원본을 반환
};

function KakaoLogoutHandle() {
  const base_url = extractBaseUrl(window.location.href);

  const Rest_api_key = conf.kakao_restapi;
  const redirect_uri = base_url + "/logout";
  const kakaoURL = `https://kauth.kakao.com/oauth/logout?client_id=${Rest_api_key}&logout_redirect_uri=${redirect_uri}`;
  const handleLogout = () => {
    window.location.href = kakaoURL;
  };
  return <div onClick={handleLogout}>Log Out</div>;
}
export default KakaoLogoutHandle;
