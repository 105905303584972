import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  InputBase,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import conf from "../../config/conf_sql.json";
import "../../App.css"; // 스타일 변경을 위한 CSS 파일 임포트
import Linkify from "react-linkify";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function MessageList({
  crew_id,
  club_id,
  activity_id,
  notice_id,
  crew_nick,
  api,
  order,
  text,
  admin_level,
}) {
  const [messageList, setMessageList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const sortOrder = order === "asc" ? 1 : -1;

  useEffect(() => {
    callApi();
  }, [api]);

  const callApi = async () => {
    try {
      const response = await ApiHeader.get(api);
      setMessageList(response.data);
    } catch (e) {
      ErrorHandler(e, "MessageList.js, callApi, get message data");
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  //db에 메세지 추가
  const addMessage = async (newMessage) => {
    try {
      if (activity_id) {
        await ApiHeader.post(`/api/v1/messages/activity`, {
          crew_id: hashids.decode(crew_id),
          club_id: hashids.decode(club_id),
          activity_id: hashids.decode(activity_id),
          message_text: newMessage,
        });
      } else if (notice_id) {
        await ApiHeader.post(`/api/v1/messages/notice`, {
          crew_id: hashids.decode(crew_id),
          club_id: hashids.decode(club_id),
          notice_id: hashids.decode(notice_id),
          message_text: newMessage,
        });
      }
    } catch (e) {
      ErrorHandler(e, "MessageList.js, addMessage, post message data");
    }
  };

  const handleAddMessage = () => {
    if (newMessage.trim() === "") return;
    addMessage(newMessage);
    const timeOffset = conf.time_offset === "+09:00" ? 9 : 0;
    const newMessageObj = {
      id: messageList.length ? messageList[messageList.length - 1].id + 1 : 1,
      created_time: new Date(
        new Date().getTime() + timeOffset * 60 * 60 * 1000
      ).toISOString(),
      nick: crew_nick,
      message_text: newMessage,
      sort: messageList.length
        ? messageList[messageList.length - 1].sort + 1
        : 1,
    };

    setMessageList([...messageList, newMessageObj]);
    setNewMessage("");
  };

  // 메시지를 삭제하는 API를 호출하는 함수
  const deleteMessage = async (messageId) => {
    try {
      await ApiHeader.delete(`/api/v1/messages/${hashids.encode(messageId)}`);
    } catch (e) {
      ErrorHandler(e, "MessageList.js, deleteMessage, delete message data");
    }
  };

  // 메시지 삭제 버튼의 클릭 이벤트 핸들러
  const handleDeleteMessage = (messageId) => {
    deleteMessage(messageId);

    // 메시지 목록에서 삭제된 메시지를 제거
    const updatedMessageList = messageList.filter(
      (message) => message.id !== messageId
    );
    setMessageList(updatedMessageList);
  };

  // 삭제 확인 대화 상자가 열려 있는지 나타내는 상태
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  // 삭제할 메시지의 ID
  const [messageIdToDelete, setMessageIdToDelete] = React.useState(null);

  // 삭제 버튼의 클릭 이벤트 핸들러
  const handleDeleteButtonClick = (messageId) => {
    setMessageIdToDelete(messageId);
    setDeleteDialogOpen(true);
  };

  // 삭제 확인 대화 상자의 '삭제' 버튼의 클릭 이벤트 핸들러
  const handleConfirmDelete = () => {
    handleDeleteMessage(messageIdToDelete);
    setDeleteDialogOpen(false);
  };

  // 삭제 확인 대화 상자의 '취소' 버튼의 클릭 이벤트 핸들러
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const filterSortData = messageList
    .filter((row) => row.message_text.toLowerCase().includes(searchTerm))
    .sort((a, b) => (a.sort > b.sort ? sortOrder : -sortOrder));

  // 메시지 목록을 렌더링하는 코드
  const tableData = filterSortData.map((row) => (
    <TableRow key={row.id}>
      <TableCell key={`${row.id}-date`} sx={{ width: 75 }}>
        <span className="small-text">
          {moment(row.created_time)
            .subtract(conf.time_offset, "hours")
            .format("M/D(ddd) H:mm")}
        </span>
        <br />
        {row.nick}
        {(admin_level >= 8 || crew_id === hashids.encode(row.crew_id)) && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleDeleteButtonClick(row.id);
            }}
            style={{
              padding: "5px",
              fontSize: "70%",
              lineHeight: "1",
              textDecoration: "none",
            }}
          >
            삭제
          </a>
        )}
      </TableCell>
      <TableCell style={{ whiteSpace: "pre-wrap" }}>
        <Linkify>{row.message_text}</Linkify>
      </TableCell>
    </TableRow>
  ));

  return (
    <div>
      <Toolbar>
        <Typography>{text}</Typography>
        <Typography
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        />
        <SearchIcon />
        <InputBase
          type="text"
          className="search"
          onChange={handleChange}
          placeholder="Search..."
        />
      </Toolbar>
      <Table>
        <TableBody>{tableData}</TableBody>
      </Table>
      {(activity_id || notice_id) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px",
            width: "90%",
          }}
        >
          <TextField
            label="새 메세지"
            value={newMessage}
            onChange={handleNewMessageChange}
            variant="outlined"
            style={{ marginRight: "10px" }}
            size="small"
            multiline
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMessage}
          >
            추가
          </Button>
        </div>
      )}

      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>메시지 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText>이 메시지를 삭제하시겠습니까?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>취소</Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MessageList;
