import axios from "axios";
import conf from "../../config/conf_sql.json";
import ErrorHandler from "../Common/ErrorHandler";

let isRefreshing = false; // refreshToken 실행 중인지 추적하는 변수

const getKaKaoUserData = async (token) => {
  try {
    const kakaoUser = await axios.get("https://kapi.kakao.com/v2/user/me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return kakaoUser.data;
  } catch (e) {
    ErrorHandler(e, "KakaoLogin.js, getKaKaoUserData");
  }
};

const saveLoginInfo = async (info) => {
  try {
    await ApiHeader.post("/api/v1/accesslogs", {
      user_id: info.id,
      user_name: info.kakao_account.profile.nickname,
      user_email: info.kakao_account.email,
      user_image: info.kakao_account.profile.profile_image_url,
      token: info.token_crewwith,
      refresh_token: info.token_refresh,
    });
  } catch (e) {
    ErrorHandler(e, "KakaoLogin.js, saveLoginInfo");
  }
};

const refreshToken = async () => {
  const lastRefreshTime = localStorage.getItem("LAST_REFRESH_TIME");
  const currentTime = Date.now();

  // 마지막 갱신으로부터 48분(48*60*1000ms)이 경과했는지 확인
  if (lastRefreshTime && currentTime - lastRefreshTime < 48 * 60 * 1000) {
    return null;
  }

  if (isRefreshing) {
    return null; // 이미 갱신 중이면 실행하지 않음
  }

  isRefreshing = true;

  try {
    const refreshToken = JSON.parse(localStorage.getItem("TOKEN_REFRESH"));
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      client_id: conf.kakao_restapi,
      refresh_token: refreshToken,
    });
    const res = await axios.post("https://kauth.kakao.com/oauth/token", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
    const newAccessToken = res.data.access_token;

    const userInfo = await getKaKaoUserData(newAccessToken);
    userInfo.token_crewwith = newAccessToken;
    userInfo.token_refresh = refreshToken;

    saveLoginInfo(userInfo);

    localStorage.setItem("TOKEN_CREWWITH", newAccessToken);
    localStorage.setItem("LAST_REFRESH_TIME", currentTime); // 마지막 갱신 시간 저장

    return newAccessToken;
  } catch (e) {
    ErrorHandler(e, "refreshJwtToken");
    return null;
  } finally {
    isRefreshing = false; // 실행 종료 후 다시 실행 가능하도록 플래그 리셋
  }
};

const ApiHeader = axios.create();

ApiHeader.interceptors.request.use(
  (config) => {
    const adminToken = localStorage.getItem("jwtoken");
    const refreshToken = localStorage.getItem("jwtoken_refresh");

    config.headers["authorization"] = adminToken;
    config.headers["x-refresh-token"] = refreshToken; // refresh token을 헤더에 추가

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ApiHeader.interceptors.response.use(
  (response) => {
    // 서버로부터 새로운 토큰이 반환된 경우 이를 저장
    if (response.headers["authorization"]) {
      if (
        localStorage.getItem("jwtoken") !== response.headers["authorization"]
      ) {
        localStorage.setItem("jwtoken", response.headers["authorization"]);
        refreshToken(); // 조건에 맞으면 실행
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default ApiHeader;
