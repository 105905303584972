export const holidaysWithoutYear = [
  "01-01", // 새해 첫날
  "03-01", // 삼일절
  "05-05", // 어린이날
  "06-06", // 현충일
  "08-15", // 광복절
  "10-03", // 개천절
  "10-09", // 한글날
  "12-25", // 크리스마스
];

export const holidaysWithYear = [
  "2022-01-31",
  "2022-02-01",
  "2022-02-02",
  "2022-03-09",
  "2022-06-01",
  "2022-09-09",
  "2022-09-12",
  "2022-10-10",
  "2023-01-23",
  "2023-01-24",
  "2023-05-29",
  "2023-09-28",
  "2023-09-29",
  "2023-10-02",
  "2024-02-09",
  "2024-02-12",
  "2024-04-10",
  "2024-05-06",
  "2024-05-15",
  "2024-09-16",
  "2024-09-17",
  "2024-09-18",
  "2024-10-01",
  "2025-01-28",
  "2025-01-29",
  "2025-01-30",
  "2025-03-03",
  "2025-05-06",
  "2025-10-06",
  "2025-10-07",
  "2025-10-08",
  "2026-02-16",
  "2026-02-17",
  "2026-02-18",
  "2026-03-02",
  "2026-05-25",
  "2026-09-24",
  "2026-09-25",
];
