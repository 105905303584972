import React, { useEffect, useState } from "react";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

const Terms = () => {
  const [terms, setTerms] = useState("");
  const [personal, setPersonal] = useState("");

  useEffect(() => {
    ApiHeader.get("/이용약관.txt")
      .then((response) => {
        setTerms(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "Terms.js, get terms");
      });
    ApiHeader.get("/개인정보.txt")
      .then((response) => {
        setPersonal(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "Terms.js, get personal");
      });
  }, []);

  return (
    <div>
      <h1>이용약관 및 개인정보 처리방침</h1>
      <h1></h1>
      <h2>이용약관</h2>
      <pre>{terms}</pre>
      <h1></h1>
      <h2>개인정보 처리방침</h2>
      <pre>{personal}</pre>
    </div>
  );
};

export default Terms;
