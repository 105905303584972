import React from "react";

export default function ClubLevel() {
  return (
    <div>
      <p style={{ color: "gray", fontSize: "small" }}>
        크루 레벨 별 권한 <br />
        . lv9: 클럽 이관
        <br />
        . lv8: 클럽 수정, 회원등급 수정, 모임 수정/삭제
        <br />
        . lv7: 출석 관리, 회원정보 조회
        <br />
        . lv6: 모임 생성
        <br />
        . lv5: 모임(lv5 이상) 생성
        <br />
        . lv4: 회원 조회
        <br />
        . lv3: 모임 참석자 조회
        <br />
        . lv2: 지난 모임 조회
        <br />
        . lv1: 모임 참석
        <br />
      </p>
    </div>
  );
}
