import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function KakaoLogout() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("TOKEN_CREWWITH");
    localStorage.removeItem("TOKEN_REFRESH");
    localStorage.removeItem("LAST_REFRESH_TIME");
    secureLocalStorage.removeItem("DATA_CREWWITH");
    localStorage.removeItem("DATA_CREWWITH");
    localStorage.removeItem("token");
    localStorage.removeItem("jwtoken");
    localStorage.removeItem("jwtoken_refresh");
    navigate("/");
  }, []);

  return <></>;
}

export default KakaoLogout;
