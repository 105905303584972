import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import useCrewData from "../Common/CrewData";
import TopAppBar from "../Main/TopAppBar";
import ApiHeader from "../Common/ApiHeader";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";
import ImageUpload from "../Image/ImageUpload";

const categories = [
  "규정",
  "공지",
  "자유글",
  "모임",
  "러닝",
  "대회",
  "용품",
  "마켓",
  "챌린지",
];

const CategorySelect = ({ vCategory, setCategory, adminLevel }) => {
  const availableCategories =
    adminLevel >= 7 ? categories : categories.slice(2);
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="category-label" sx={{ fontSize: 16, mb: 0.5 }}>
        Category
      </InputLabel>
      <Select
        labelId="category-label"
        id="category-select"
        value={vCategory}
        label="Category"
        onChange={(e) => setCategory(e.target.value)}
      >
        {availableCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function NoticeCreate() {
  const { club_nick, notice_id } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  const location = useLocation();
  const cCategory =
    location.state != null && location.state.notice_category != null
      ? location.state.notice_category
      : "";
  const cTitle =
    location.state != null && location.state.notice_title != null
      ? location.state.notice_title
      : "";
  const cText =
    location.state != null && location.state.notice_text != null
      ? location.state.notice_text
      : "";
  const cImage =
    location.state != null && location.state.notice_image != null
      ? location.state.notice_image
      : "";
  const type =
    location.state != null && location.state.type != null
      ? location.state.type
      : "";
  const [vCategory, setCategory] = useState(cCategory);
  const [vTitle, setTitle] = useState(cTitle);
  const [vText, setText] = useState(cText);
  const [vImage, setImage] = useState(cImage);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // location.state에서 timeAuthorized 값 가져오기
  const { timeAuthorized } = location.state || { timeAuthorized: 0 };
  useEffect(() => {
    const currentTime = Date.now();
    if (currentTime - timeAuthorized <= 10000) {
      // 10초 이내인지 확인
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [timeAuthorized]);

  const saveNotice = async (uploadedIds) => {
    if (type === "put") {
      await ApiHeader.put(`/api/v1/notices/${notice_id}`, {
        notice_category: vCategory,
        notice_title: vTitle,
        notice_text: vText,
      });
      navigate(`/${club_nick}/notice/${notice_id}`);
    } else {
      const responseMaxId = await ApiHeader.get("/api/v1/notices/max_id");
      const newNotId = responseMaxId.data.max_id + 1;
      await ApiHeader.post("/api/v1/notices", {
        id: newNotId,
        crew_id: hashids.decode(crewId)[0],
        club_id: hashids.decode(clubId)[0],
        notice_category: vCategory,
        notice_title: vTitle,
        notice_text: vText,
        notice_image: uploadedIds,
      });
      navigate(`/${club_nick}/notice/${hashids.encode(newNotId)}`);
    }
  };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isOK, setIsOK] = useState(false);
  const navigate = useNavigate();
  const ImageUploadRef = useRef();

  const eventClick = () => {
    if (vCategory !== "" && vTitle !== "" && vText !== "") {
      setMessage(
        notice_id ? "게시 수정 하시겠습니까?" : "게시 등록 하시겠습니까?"
      );
      setIsOK(true);
      setOpen(true);
    } else {
      setMessage("모두 작성해 주시기 바랍니다.");
      setIsOK(false);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = async () => {
    if (isOK && !isSubmitting) {
      setIsSubmitting(true);
      try {
        if (!notice_id) {
          await handleImageUpload();
        } else {
          await saveNotice(undefined);
        }
      } catch (e) {
        ErrorHandler(e, "NoticeCreate.js, handleAgree()");
      } finally {
        setIsSubmitting(false);
      }
    }
    setOpen(false);
  };

  const handleImageUpload = async () => {
    try {
      const imageUpload = ImageUploadRef.current;
      if (imageUpload) {
        await imageUpload.uploadFiles();
      }
    } catch (e) {
      ErrorHandler(e, "NoticeCreate.js, handleImageUpload()");
    }
  };

  const handleUpload = async (uploadedIds) => {
    if (uploadedIds && uploadedIds.length > 0) {
      setImage(uploadedIds);
    }
    await saveNotice(uploadedIds); // 이미지 업로드 후 저장
  };

  return isAuthorized ? (
    <>
      <TopAppBar club_nick={club_nick} />
      <Box
        sx={{
          maxWidth: "800px",
          margin: 1,
        }}
      >
        <FormControl fullWidth>
          <FormLabel id="create" sx={{ fontSize: 32 }}>
            {notice_id ? "게시 수정" : "게시 등록"}
          </FormLabel>
          <br />
          <FormLabel id="name" sx={{ fontSize: 16, mb: 0.5 }}>
            카테고리
          </FormLabel>
          <CategorySelect
            vCategory={vCategory}
            setCategory={setCategory}
            adminLevel={adminLevel}
          />
          <br />
          <FormLabel id="name" sx={{ fontSize: 16, mb: 0.5 }}>
            제목
          </FormLabel>
          <TextField
            required
            id="name"
            label="Title"
            value={vTitle}
            onChange={(e) => setTitle(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="text" sx={{ fontSize: 16, mb: 0.5 }}>
            내용
          </FormLabel>
          <TextField
            required
            id="text"
            label="Text"
            value={vText}
            onChange={(e) => setText(e.target.value)}
            size="small"
            multiline
          />
          {!notice_id && (
            <ImageUpload
              ref={ImageUploadRef}
              club_nick={club_nick}
              handleUpload={handleUpload}
            />
          )}
          <br />
          <React.Fragment>
            <Button
              variant="contained"
              onClick={eventClick}
              disabled={isSubmitting}
            >
              {notice_id ? "수정" : "등록"}
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                {notice_id ? "게시 수정 확인" : "게시 등록 확인"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} disabled={isSubmitting}>
                  취소
                </Button>
                <Button onClick={handleAgree} autoFocus disabled={isSubmitting}>
                  확인
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </FormControl>
      </Box>
    </>
  ) : (
    <div>
      <TopAppBar club_nick={club_nick} />
      잘못된 접근입니다.
    </div>
  );
}

export default NoticeCreate;
