import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import axios from "axios";
import ErrorHandler from "../Common/ErrorHandler";

function ClubPublicInfo() {
  const { club_nick } = useParams();
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [count, setCount] = useState(0);
  const [clubInfo, setClubInfo] = useState("");

  useEffect(() => {
    axios
      .get(`/api/v1/clubs/information/${club_nick}`)
      .then((response) => {
        setName(response.data.name);
        setCategory(response.data.category);
        setCount(response.data.crew_count);
        setClubInfo(response.data.info);
      })
      .catch((e) => {
        ErrorHandler(e, "ClubPublicInfo.js, get club info");
      });
  }, []);

  return (
    <>
      <Typography style={{ margin: 20, whiteSpace: "pre-wrap" }}>
        [{category}] {name} 👥{count}
      </Typography>
      <Typography style={{ margin: 20, whiteSpace: "pre-wrap" }}>
        {clubInfo}
      </Typography>
    </>
  );
}

export default ClubPublicInfo;
