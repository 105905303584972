import React, { useState, forwardRef, useImperativeHandle } from "react";
import ApiHeader from "../Common/ApiHeader";
import useCrewData from "../Common/CrewData";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import { Delete, ArrowUpward, ArrowDownward } from "@mui/icons-material";

const hashids = new Hashids(conf.secret, 10);

const ImageUpload = forwardRef(({ club_nick, handleUpload }, ref) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  useImperativeHandle(ref, () => ({
    uploadFiles,
  }));

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const totalFiles = selectedFiles.length + files.length;

    if (totalFiles > 10) {
      alert("You can only upload up to 10 images.");
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      [newFiles[index], newFiles[index - 1]] = [
        newFiles[index - 1],
        newFiles[index],
      ];
      return newFiles;
    });
  };

  const handleMoveDown = (index) => {
    if (index === selectedFiles.length - 1) return;
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      [newFiles[index], newFiles[index + 1]] = [
        newFiles[index + 1],
        newFiles[index],
      ];
      return newFiles;
    });
  };

  const uploadFiles = async () => {
    const formData = new FormData();
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("images", selectedFiles[i]);
      }
    }
    formData.append("member_id", hashids.decode(memberId)[0]);

    try {
      const response = await ApiHeader.post("/api/v1/images/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleUpload(response.data.images.toString()); // 부모 컴포넌트에 업로드된 이미지 ID 전달
    } catch (err) {
      console.error("Error uploading files:", err);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Upload Images (Max 10)
        </Typography>
        <Button variant="contained" component="label">
          Select Images
          <input type="file" multiple hidden onChange={handleFileChange} />
        </Button>
      </Box>
      <List>
        {selectedFiles.map((file, index) => (
          <ListItem key={index}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={URL.createObjectURL(file)}
                alt={`selected ${file.name}`}
                width="200"
                style={{ marginRight: 20 }}
              />
            </Box>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="move up"
                onClick={() => handleMoveUp(index)}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="move down"
                onClick={() => handleMoveDown(index)}
              >
                <ArrowDownward />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveFile(index)}
                sx={{ ml: 2 }}
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
});

export default ImageUpload;
