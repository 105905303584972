import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);

function CrewInfo({ api, adminLevel, club_nick }) {
  const [crewData, setCrewData] = useState([]);
  const [infoData, setInfoData] = useState({});
  const [clubLevels, setClubLevels] = useState({});
  const [selectedLevel, setSelectedLevel] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newLevel, setNewLevel] = useState("");

  useEffect(() => {
    ApiHeader.get(api)
      .then((response) => {
        setCrewData(response.data);
        setInfoData(JSON.parse(response.data.info));
        setSelectedLevel(response.data.admin_level);
      })
      .catch((e) => {
        ErrorHandler(e, "CrewInfo.js, useEffect, get crew data");
      });
  }, [api]);

  useEffect(() => {
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`)
      .then((response) => {
        setClubLevels(response.data.levels && JSON.parse(response.data.levels));
      })
      .catch((e) => {
        ErrorHandler(e, "CrewInfo.js, useEffect, get club levels");
      });
  }, [club_nick]);

  const handleLevelChange = (event) => {
    setNewLevel(event.target.value);
    setDialogOpen(true);
  };

  const handleDialogClose = (confirm) => {
    if (confirm) {
      setSelectedLevel(newLevel);
      ApiHeader.put(
        `/api/v1/crews/${hashids.encode(crewData.id)}/admin/${newLevel}`
      )
        .then((response) => {})
        .catch((e) => {
          ErrorHandler(e, "CrewInfo.js, handleLevelChange, put crew data");
        });
    }
    setDialogOpen(false);
  };
  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ minWidth: 70 }}>이름</TableCell>
            <TableCell>
              {crewData.nick} (
              {crewData.nick === crewData.name ? "" : crewData.name + ", "}
              {crewData.gender === "Male"
                ? "남"
                : crewData.gender === "Female"
                ? "여"
                : ""}
              , {crewData.nationality})
            </TableCell>
          </TableRow>
          {adminLevel >= 7 && (
            <TableRow>
              <TableCell>생일</TableCell>
              <TableCell>
                {moment(crewData.birthday).format("Y년 M월 D일")}
              </TableCell>
            </TableRow>
          )}
          {adminLevel >= 7 && (
            <TableRow>
              <TableCell>사는곳</TableCell>
              <TableCell>{crewData.address}</TableCell>
            </TableRow>
          )}
          {adminLevel >= 8 && (
            <TableRow>
              <TableCell>이메일</TableCell>
              <TableCell>{crewData.email}</TableCell>
            </TableRow>
          )}
          {adminLevel >= 8 && (
            <TableRow>
              <TableCell>연락처</TableCell>
              <TableCell>{crewData.contact}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>가입일</TableCell>
            <TableCell>
              {moment(crewData.created_time)
                .subtract(conf.time_offset)
                .format("Y년 M월 D일")}
            </TableCell>
          </TableRow>
          {Object.entries(infoData).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell style={{ whiteSpace: "pre-wrap" }}>{value}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>크루 등급</TableCell>
            <TableCell>
              {adminLevel >= 8 && crewData.admin_level < adminLevel ? (
                <Select
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  size="small"
                >
                  {Object.entries(clubLevels)
                    .filter(
                      ([level, label]) => level < adminLevel && label !== ""
                    )
                    .map(([level, label]) => (
                      <MenuItem key={level} value={level}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                clubLevels[crewData.admin_level]
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
        <DialogTitle>등급 변경 확인</DialogTitle>
        <DialogContent>
          <DialogContentText>
            이 회원의 등급을 변경하시겠습니까? <br />
            {clubLevels[selectedLevel]} → {clubLevels[newLevel]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            취소
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CrewInfo;
