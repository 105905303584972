import React from "react";
import ClubList from "./ClubList";
import Welcome from "./Welcome";
import ActivityCalendar from "../Activity/ActivityCalendar";
import { Typography } from "@mui/material";
import TopAppBar from "./TopAppBar";
import useCrewData from "../Common/CrewData";

function Main() {
  const { memberId, clubId, crewId, adminLevel } = useCrewData(undefined);

  if (memberId !== "") {
    return (
      <div className="Main">
        <TopAppBar />
        <ClubList memberId={memberId} />
        <Typography key="tg_mine" variant="h6" sx={{ ml: 2, mb: 1 }}>
          My Schedule
        </Typography>
        <ActivityCalendar club_nick={"personal"} />
      </div>
    );
  } else {
    return (
      <>
        <TopAppBar />
        <Welcome />
      </>
    );
  }
}

export default Main;
