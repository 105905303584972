import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import {
  Card,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";
import Groups3Icon from "@mui/icons-material/Groups3";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function SimpleDialog(props) {
  const { onClose, open, subgroupList } = props;

  const handleClose = () => {
    onClose("");
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>참석할 소그룹 선택</DialogTitle>
      <List sx={{ pt: 0 }}>
        {subgroupList.map((subgroup) => (
          <ListItem disableGutters key={subgroup}>
            <ListItemButton onClick={() => handleListItemClick(subgroup)}>
              <ListItemText primary={subgroup} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

function ConfirmationDialog(props) {
  const { open, onClose, title, content, onConfirm } = props;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>취소</Button>
        <Button onClick={handleConfirm} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ActivityCard(props) {
  const { onActivityChange, club_nick, memberId, crewId, adminLevel } = props;
  const {
    id,
    activity_time,
    subgroup,
    max_crew,
    info,
    name,
    location,
    location_url,
    attend_level,
  } = props.row;
  const [btnColor, setBtnColor] = useState("error");
  const [btnVariant, setBtnVariant] = useState("text");
  const [btnText, setBtnText] = useState("");
  const [attendeeNum, setAttendeeNum] = useState(0);
  const [isApplied, setIsApplied] = useState(false);
  const [subgroupList, setSubgroupList] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [clubNick, setClubNick] = useState(club_nick);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id || !memberId) return;
        const activityId = hashids.encode(id);

        const resAttendeeNum = await ApiHeader.get(
          `/api/v1/activities/${activityId}/crews/count`
        );
        setAttendeeNum(resAttendeeNum.data[0].count);

        const resApplied = await ApiHeader.get(
          `/api/v1/attendees/member/${memberId}/activity/${activityId}/count`
        );
        setIsApplied(resApplied.data[0].count > 0);

        if (subgroup) {
          setSubgroupList(subgroup.split("\n"));
        }
      } catch (e) {
        ErrorHandler(e, "ActivityCard.js, useEffect id");
      }
    };

    fetchData();
  }, [id, memberId]);

  // club_nick이 all이거나 personal이면 api 호출
  useEffect(() => {
    if (club_nick === "all" || club_nick === "personal") {
      const activityId = hashids.encode(id);
      ApiHeader.get(`/api/v1/activities/${activityId}/club_nick`)
        .then((response) => {
          setClubNick(response.data.nick);
        })
        .catch((e) => {
          ErrorHandler(e, "ActivityCard.js, useEffect club_nick");
        });
    }
  }, [club_nick]);

  const isPrevious = moment().isAfter(
    moment(activity_time).subtract(conf.time_offset)
  );

  useEffect(() => {
    updateButtonState();
  }, [isApplied, isPrevious, attendeeNum, max_crew]);

  const handleAttendClose = async (value) => {
    try {
      await ApiHeader.post("/api/v1/attendees", {
        crew_id: hashids.decode(crewId)[0],
        activity_id: id,
        subgroup: value,
        state: 1,
      });
      setIsApplied(true);
      setAttendeeNum((prevNum) => prevNum + 1); // 참석 인원 수 증가
      updateButtonState();
      if (typeof onActivityChange === "function") {
        onActivityChange();
      }
    } catch (e) {
      ErrorHandler(e, "ActivityCard.js, handleAttend");
    }
    setOpen(false);
  };

  const handleConfirm = async () => {
    if (confirmAction === "cancel") {
      try {
        await ApiHeader.delete(
          `/api/v1/attendees/member/${memberId}/activity/${hashids.encode(id)}`
        );
        setIsApplied(false);
        setAttendeeNum((prevNum) => prevNum - 1); // 참석 인원 수 감소
        if (typeof onActivityChange === "function") {
          onActivityChange();
        }
      } catch (e) {
        ErrorHandler(e, "ActivityCard.js, handleConfirm");
      }
    } else if (confirmAction === "attend") {
      subgroupList.length > 1
        ? setOpen(true)
        : await handleAttendClose(subgroupList[0]);
    }
  };

  const updateButtonState = () => {
    if (isPrevious) {
      setBtnText("종료");
      setBtnVariant("outlined");
      setBtnColor("error");
    } else if (isApplied) {
      setBtnText("취소");
      setBtnVariant("contained");
      setBtnColor("error");
    } else if (attendeeNum >= max_crew) {
      setBtnText("마감");
      setBtnVariant("outlined");
      setBtnColor("error");
    } else if (attend_level > adminLevel) {
      setBtnText("불가");
      setBtnVariant("outlined");
      setBtnColor("error");
    } else {
      setBtnText("참석");
      setBtnVariant("contained");
      setBtnColor("primary");
    }
  };

  const eventClick = async () => {
    if (btnText === "취소") {
      setConfirmAction("cancel");
      setConfirmOpen(true);
    } else if (btnText === "참석") {
      setConfirmAction("attend");
      setConfirmOpen(true);
    }
  };

  return (
    <div>
      <Card key={id} elevation={3} sx={{ margin: 2, display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Link
            to={`/${clubNick}/activity/${hashids.encode(id)}`}
            state={{ info: info }}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Typography
              sx={{ fontSize: 16, margin: 0.3, color: "primary.main" }}
            >
              {name}
            </Typography>
          </Link>
          <Typography sx={{ fontSize: 13 }}>
            <CalendarMonthIcon sx={{ fontSize: 13 }} />{" "}
            {moment(activity_time)
              .subtract(conf.time_offset)
              .format("M월 D일(ddd) A h:mm")}
          </Typography>
          <Typography sx={{ fontSize: 13 }}>
            <PlaceIcon sx={{ fontSize: 13 }} /> {location}
            {location_url && location_url.length >= 2 && (
              <Link
                to={location_url}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                {" "}
                (지도보기)
              </Link>
            )}
          </Typography>
        </Box>
        <Groups3Icon sx={{ fontSize: 13, mt: 1.3, ml: 1.2, mr: 0.5 }} />
        <Typography sx={{ fontSize: 13, mt: 1 }}>
          {attendeeNum} / {max_crew}
        </Typography>
        <Button
          variant={btnVariant}
          color={btnColor}
          onClick={eventClick}
          size="small"
          sx={{ margin: 2, display: "flex", ml: "auto" }}
        >
          {btnText}
        </Button>
        <SimpleDialog
          subgroupList={subgroupList}
          open={open}
          onClose={handleAttendClose}
        />
        <ConfirmationDialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          title={btnText === "취소" ? "참석 취소" : "참석 신청"}
          content={
            btnText === "취소"
              ? "참석을 취소 하시겠습니까?"
              : "참석 신청 하시겠습니까?"
          }
          onConfirm={handleConfirm}
        />
      </Card>
    </div>
  );
}

export default ActivityCard;
