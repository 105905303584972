import React from "react";
import Paper from "@mui/material/Paper";
import conf from "../../config/conf_sql.json";

function ClubImage({ club_nick }) {
  const now = new Date();
  const idx = now.getSeconds() % 10;
  const imgPath =
    conf.media + "/uploads/" + club_nick.toLowerCase() + "_" + idx + ".png";

  return (
    <div className="MainImage">
      <Paper
        key="image"
        elevation={0}
        sx={{ margin: 2, backgroundColor: "transparent" }}
      >
        <img key={idx} className="img" alt={club_nick} src={imgPath} />
      </Paper>
    </div>
  );
}

export default ClubImage;
