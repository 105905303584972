import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { AppBar, Toolbar, Box } from "@mui/material";
import TopRight from "./TopRight";
import TopBottom from "./TopBottom";
import useCrewData from "../Common/CrewData";
import NoShowWarning from "./NoShowWarning";
import PopupInDay from "./PopupInDay";
import PopupInHour from "./PopupInHour";
import PopupWelcome from "./PopupWelcome";

function TopAppBar({ club_nick }) {
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed" // AppBar를 고정 위치로 설정
        sx={{
          maxWidth: "800px",
          height: "55px",
          margin: "auto",
          left: 0,
          right: 0,
          bgcolor: club_nick
            ? club_nick.toUpperCase() === "JTR"
              ? "#000000"
              : "#E6007E"
            : undefined,
          zIndex: (theme) => theme.zIndex.drawer + 2, // zIndex를 높게 설정하여 다른 요소들 위에 표시
        }}
      >
        <Toolbar>
          {club_nick ? (
            <>
              <Link
                to={`/${club_nick}/`}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <img
                  key="img_logo"
                  className="img"
                  alt={club_nick}
                  src={
                    club_nick.toUpperCase() === "JTR"
                      ? "/logo_jtr.png"
                      : "/logo_yrc.png"
                  }
                  width={35}
                />
              </Link>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                &nbsp;
                {club_nick.toUpperCase() === "JTR"
                  ? "저스트레일"
                  : "양재천 러닝 크루"}
              </Typography>
            </>
          ) : (
            <>
              <Link
                to="/"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  &nbsp;{"CrewWITH"}
                </Typography>
              </Link>
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <TopRight logout={memberId === ""} club_nick={club_nick} />
        </Toolbar>
      </AppBar>
      <Toolbar />
      {club_nick && (
        <>
          <Box
            sx={{
              position: "fixed", // TopBottom을 고정 위치로 설정
              top: "55px", // AppBar 아래에 위치하도록 설정 (AppBar의 높이만큼 offset 적용)
              width: "100%", // 가로 너비를 전체 화면에 맞춤
              zIndex: (theme) => theme.zIndex.drawer + 1, // AppBar 바로 아래에 위치하도록 zIndex 설정
              bgcolor: "background.paper", // 필요에 따라 배경색 설정
            }}
          >
            <TopBottom
              memberId={memberId}
              clubId={clubId}
              crewId={crewId}
              adminLevel={adminLevel}
            />
          </Box>
          {crewId && (
            <Box sx={{ mt: "40px" }}>
              {" "}
              <NoShowWarning club_nick={club_nick} crewId={crewId} />
              <PopupInDay club_nick={club_nick} crewId={crewId} />
              <PopupInHour club_nick={club_nick} crewId={crewId} />
              <PopupWelcome club_nick={club_nick} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default TopAppBar;
