import React from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Stack, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

function TopBottom(props) {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = props;

  const handleRefresh = () => {
    window.location.reload();
  };

  if (adminLevel > 0) {
    return (
      <Stack spacing={2} direction="row">
        <Link
          to={`/${club_nick}/notices`}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <Button variant="text">공지</Button>
        </Link>
        <Link
          to={`/${club_nick}/activities`}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <Button variant="text">모임</Button>
        </Link>
        <Link
          to={`/${club_nick}/notices/bulletin`}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <Button variant="text">게시판</Button>
        </Link>
        {adminLevel >= 4 && (
          <>
            <Link
              to={`/${club_nick}/crews`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <Button variant="text">크루</Button>
            </Link>
          </>
        )}
        <IconButton onClick={handleRefresh} color="primary" size="small">
          <RefreshIcon />
        </IconButton>
      </Stack>
    );
  }

  return null; // Return null if adminLevel is 0 or less
}

export default TopBottom;
