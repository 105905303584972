import React from "react";
import ActivityCard from "./ActivityCard";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import { Typography } from "@mui/material";

function ActivityCardList(props) {
  const { list, date, club_nick, memberId, crewId, adminLevel } = props;
  const activityDatas = list.slice(0, 3).map((row) => {
    return <ActivityCard key={row.id} row={row} club_nick={club_nick} />;
  });

  const activityDate = list
    .filter((row) =>
      moment(row.activity_time)
        .subtract(conf.time_offset)
        .format("YYYY-MM-DD")
        .includes(date)
    )
    .sort((a, b) => (moment(a.activity_time).isAfter(b.activity_time) ? 1 : -1)) // 시간 순으로 정렬
    .map((row) => {
      return (
        <ActivityCard
          key={row.id}
          row={row}
          club_nick={club_nick}
          memberId={memberId}
          crewId={crewId}
          adminLevel={adminLevel}
        />
      );
    });

  if (date) {
    if (activityDate.length === 0) {
      return <Typography align="center">{date} 모임이 없습니다.</Typography>;
    }
    return activityDate;
  } else {
    return activityDatas;
  }
}

export default ActivityCardList;
