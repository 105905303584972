import React from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "@mui/material";
import TopAppBar from "../Main/TopAppBar";
import NoticeList from "./NoticeList";
import useCrewData from "../Common/CrewData";

function Bulletin() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  return (
    <div>
      <TopAppBar club_nick={club_nick} />
      {adminLevel > 0 && (
        <>
          <NoticeList
            order="desc"
            text="Bulletin Board"
            api={`/api/v1/notices/club_nick/${club_nick}`}
            club_nick={club_nick}
            crew_id={crewId}
            club_id={clubId}
            crew_nick={crewId}
            admin_level={adminLevel}
            needSearch={true}
          />
          <Button
            variant="contained"
            sx={{ mt: 1, mr: 3, display: "flex", ml: "auto" }}
          >
            <Link
              to={`/${club_nick}/notice/create`}
              state={{ timeAuthorized: Date.now() }}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              글쓰기
            </Link>
          </Button>
        </>
      )}
    </div>
  );
}

export default Bulletin;
