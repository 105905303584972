import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);

const PopupInDay = ({ club_nick, crewId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [hideForDay, setHideForDay] = useState(false);

  useEffect(() => {
    const lastHiddenTime = localStorage.getItem("hideActivityDayUntil");
    const now = moment();
    if (lastHiddenTime && now.isBefore(moment(lastHiddenTime))) {
      return; // Do not show the warning
    }

    const checkTodayActivities = async () => {
      if (!crewId) return;
      try {
        const response = await ApiHeader.get(
          `/api/v1/crews/${crewId}/activities`
        );
        const todayActivities = response.data
          .filter((activity) =>
            moment(activity.activity_time)
              .subtract(conf.time_offset)
              .isBetween(moment().add(1, "hours"), moment().add(1, "days"))
          )
          .sort((a, b) => moment(a.activity_time) - moment(b.activity_time));
        if (todayActivities.length > 0) {
          setActivities(todayActivities);
          setDialogOpen(true);
        }
      } catch (e) {
        ErrorHandler(e, "PopupInDay.js, checkTodayActivities");
      }
    };

    checkTodayActivities();
  }, [crewId]);

  const handleClose = () => {
    if (hideForDay) {
      localStorage.setItem(
        "hideActivityDayUntil",
        moment().add(1, "days").toISOString()
      );
    }
    setDialogOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setHideForDay(event.target.checked);
  };

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Upcoming Activities</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            24시간 내에 신청한 모임이 있어요.
          </Typography>
          {activities.map((act, index) => (
            <Link
              key={index}
              to={`/${club_nick}/activity/${hashids.encode(act.activity_id)}`}
              style={{ color: "#3f50b5", textDecoration: "inherit" }}
            >
              <Typography key={index} sx={{ mt: 1 }}>
                {moment(act.activity_time)
                  .subtract(conf.time_offset)
                  .format("YYYY-MM-DD HH:mm")}
                <br />
                {act.name}
              </Typography>
            </Link>
          ))}
          <Typography sx={{ mt: 2, mb: 2 }}>잊지 말고 참여해주세요.</Typography>
          <FormControlLabel
            control={
              <Checkbox checked={hideForDay} onChange={handleCheckboxChange} />
            }
            label="하루 동안 이 창을 숨기기"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopupInDay;
