import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopAppBar from "../Main/TopAppBar";
import MessageList from "../Message/MessageList";
import useCrewData from "../Common/CrewData";

function Messages() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  if (adminLevel > 0) {
    return (
      <div>
        <TopAppBar club_nick={club_nick} />
        <MessageList
          order="desc"
          text="Voice of Crew"
          api={`/api/v1/messages/clubs/${clubId}`}
          club_nick={club_nick}
          crew_id={crewId}
          club_id={clubId}
          crew_nick={crewId}
          admin_level={adminLevel}
        />
      </div>
    );
  } else {
    return (
      <>
        <TopAppBar club_nick={club_nick} />
      </>
    );
  }
}

export default Messages;
