import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

const login = async (member_id, password) => {
  try {
    const response = await axios.post("/api/v1/login", {
      member_jwt: member_id,
      password,
    });
    if (response.data.token) {
      localStorage.setItem("jwtoken", response.data.token);
      localStorage.setItem("jwtoken_refresh", response.data.refreshToken);
    } else {
      alert("Login failed");
    }
  } catch (e) {
    ErrorHandler(e, "KakaoLogin.js, login");
  }
};

const extractBaseUrl = (url) => {
  const parts = url.split("/");
  if (parts.length > 2) {
    return parts.slice(0, 3).join("/");
  }
  return url; // URL이 기대한 형식이 아닐 경우 원본을 반환
};

function KakaoLogin() {
  const navigate = useNavigate();
  const base_url = extractBaseUrl(window.location.href);

  const getToken = async () => {
    try {
      const code = new URL(window.location.href).searchParams.get("code");
      const data = new URLSearchParams({
        grant_type: "authorization_code",
        client_id: conf.kakao_restapi,
        redirect_uri: base_url + "/oauth",
        code: code,
      });
      const res = await axios.post(
        "https://kauth.kakao.com/oauth/token",
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      );
      return res.data;
    } catch (e) {}
  };

  const getKaKaoUserData = async (token) => {
    try {
      const kakaoUser = await axios.get("https://kapi.kakao.com/v2/user/me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return kakaoUser.data;
    } catch (e) {
      ErrorHandler(e, "KakaoLogin.js, getKaKaoUserData");
    }
  };

  const callApi = async (id, token) => {
    try {
      const response = await ApiHeader.get(`/api/v1/members/kakao/${id}`);
      return response.data;
    } catch (e) {
      ErrorHandler(e, "KakaoLogin.js, callApi");
    }
  };

  const saveLoginInfo = async (info) => {
    try {
      await ApiHeader.post("/api/v1/accesslogs", {
        user_id: info.id,
        user_name: info.kakao_account.profile.nickname,
        user_email: info.kakao_account.email,
        user_image: info.kakao_account.profile.profile_image_url,
        token: info.token_crewwith,
        refresh_token: info.token_refresh,
      });
    } catch (e) {
      ErrorHandler(e, "KakaoLogin.js, saveLoginInfo");
    }
  };

  useEffect(() => {
    const loginProcess = async () => {
      try {
        const tokenData = await getToken();
        if (!tokenData) return;

        const userInfo = await getKaKaoUserData(tokenData.access_token);
        userInfo.token_crewwith = tokenData.access_token;
        userInfo.token_refresh = tokenData.refresh_token;

        await login(userInfo.id, conf.secret);

        localStorage.setItem(
          "TOKEN_CREWWITH",
          JSON.stringify(tokenData.access_token)
        );
        localStorage.setItem(
          "TOKEN_REFRESH",
          JSON.stringify(tokenData.refresh_token)
        );

        localStorage.setItem("LAST_REFRESH_TIME", Date.now()); // 마지막 갱신 시간 저장

        await saveLoginInfo(userInfo);
        const apiResponse = await callApi(userInfo.id, tokenData.access_token);

        if (apiResponse.count > 0) {
          const prevPath = sessionStorage.getItem("prevPath") || "/";
          navigate(prevPath);
        } else {
          navigate("/signup", {
            state: { timeAuthorized: Date.now() },
          });
        }
      } catch (e) {
        ErrorHandler(e, "KakaoLogin.js, useEffect, loginProcess");
      }
    };

    loginProcess();
  }, [navigate]);

  return <></>;
}

export default KakaoLogin;
