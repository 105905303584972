import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import KakaoLoginHandle from "../Login/KakaoLoginHandle";
import KakaoLogoutHandle from "../Login/KakaoLogoutHandle";

function ProfileMenu({ club_nick, logout }) {
  const [auth, setAuth] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKakaoLogin, setShowKakaoLogin] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowKakaoLogin(true);
    }, 700); // 0.7초 후에 실행

    return () => clearTimeout(timer); // 컴포넌트 unmount 시 타이머 제거
  }, []);

  const handleChange = (event) => {
    setAuth(!auth);
    handleClose();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (logout) {
    return showKakaoLogin && <KakaoLoginHandle />;
  } else {
    return (
      <div>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {club_nick && (
            <Link
              to={club_nick ? `/${club_nick}/info` : `/profile`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>Club</MenuItem>
            </Link>
          )}
          <Link
            to={club_nick ? `/${club_nick}/profile` : `/profile`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <MenuItem>Profile</MenuItem>
          </Link>
          <MenuItem checked={auth} onClick={handleChange}>
            <KakaoLogoutHandle />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

function TopRight({ club_nick, logout }) {
  return (
    <div>
      <ProfileMenu club_nick={club_nick} logout={logout} />
    </div>
  );
}

export default TopRight;
