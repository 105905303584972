import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);

const parseErrorStack = (error) => {
  const stackLines = error.stack.split("\n");
  const fileLine = stackLines[1]?.trim() || "unknown";
  const functionName = stackLines[2]?.trim().split(" ")[1] || "unknown";

  return { fileLine, functionName };
};

const ErrorHandler = async (error, type) => {
  const sls = secureLocalStorage.getItem("DATA_CREWWITH");
  let member_id = 0;
  if (sls && sls.member_id) {
    member_id = hashids.decode(sls.member_id)[0];
  }
  const { fileLine, functionName } = parseErrorStack(error);
  const errorMessage = error.toString() || "Unknown error";

  try {
    await axios.post("/api/v1/error", {
      type: `${member_id} / ${type} / ${fileLine} / ${functionName}`,
      message: errorMessage,
    });
    console.log("Error logged successfully");
  } catch (err) {
    console.error("Failed to log error:", err);
  }
};

export default ErrorHandler;
