import React from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import conf from "../../config/conf_sql.json";

const extractBaseUrl = (url) => {
  const parts = url.split("/");
  if (parts.length > 2) {
    return parts.slice(0, 3).join("/");
  }
  return url; // URL이 기대한 형식이 아닐 경우 원본을 반환
};

const KakaoLoginHandle = () => {
  const location = useLocation();
  const base_url = extractBaseUrl(window.location.href);

  const Rest_api_key = conf.kakao_restapi;
  const redirect_uri = base_url + "/oauth";
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

  const handleLogin = () => {
    sessionStorage.setItem("prevPath", location.pathname);
    window.location.href = kakaoURL;
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleLogin}
        sx={{
          bgcolor: "yellow",
          color: "black",
          "&:hover": { bgcolor: "orange" },
        }}
      >
        Kakao Login
      </Button>
    </>
  );
};
export default KakaoLoginHandle;
