import React from "react";
import { Paper } from "@mui/material";
import conf from "../../config/conf_sql.json";

function Welcome() {
  const welcome1 = conf.media + "/uploads/crewwith_welcome1.png";
  const welcome2 = conf.media + "/uploads/crewwith_welcome2.png";
  const welcome3 = conf.media + "/uploads/crewwith_welcome3.png";

  return (
    <div className="MainImage">
      <Paper
        key="image"
        elevation={0}
        sx={{ margin: 2, backgroundColor: "transparent" }}
      >
        <img key="welcome_image" className="img" alt="welcome" src={welcome1} />
        <div className="intro-container">
          <section className="intro-section">
            <h1>CrewWITH</h1>
            <p>
              We Inspire Togetherness and Health. 함께 건강한 세상을 만듭니다.
            </p>
            <p>
              CrewWITH 다양한 취미와 활동을 통해 사람들을 연결하고 건강한
              커뮤니티를 형성하는 플랫폼입니다.
            </p>
            <p>
              자신의 관심사를 공유하며, 이를 바탕으로 모임을 주최하고 참여할 수
              있습니다.
            </p>
            <p>
              CrewWITH는 특히 건강과 웰빙을 강조하여 사용자들이 더욱 건강하고
              행복한 삶을 함께 할 수 있도록 지원합니다.
            </p>
          </section>

          <section className="intro-section">
            <h2>특징</h2>
            <ul>
              <li>
                <strong>커뮤니티 형성:</strong> 같은 취미와 관심사를 가진
                사람들을 연결하기 위한 커뮤니티를 지원합니다.
              </li>
              <li>
                <strong>건강 증진:</strong> 다양한 웰빙 활동을 통해 사용자들이
                건강한 생활 습관을 유지하도록 지원합니다.
              </li>
              <li>
                <strong>포용성 확대:</strong> 누구나 쉽게 접근하고 참여할 수
                있는 포괄적인 플랫폼 제공합니다.
              </li>
            </ul>
          </section>

          <section className="intro-section">
            <h2>주요 기능</h2>
            <ul>
              <li>
                <strong>모임 및 이벤트 관리:</strong> 새로운 모임을 생성하고
                홍보할 수 있으며, 관심 있는 모임을 쉽게 찾고 참여할 수 있습니다.
              </li>
              <li>
                <strong>커뮤니티 기능:</strong> 게시판을 통한 소통과 정보 공유가
                가능합니다.
              </li>
              <li>
                <strong>사용자 프로필:</strong> 자신의 프로필을 생성하고
                관리하며, 참여한 모임의 기록을 볼 수 있습니다.
              </li>
              <li>
                <strong>회원 및 출석 관리:</strong> 운영자가 회원을 효율적으로
                관리할 수 있으며, 참여자가 출석코드를 통한 셀프 출석을 하여
                간편하게 출석 관리할 수 있습니다.
              </li>
            </ul>
          </section>

          <section className="intro-section">
            <h2>지금 가입하세요!</h2>
            <p>
              CrewWITH는 여러분의 일상에 활력을 더하고, 새로운 만남과 건강한
              삶을 위한 기회를 제공합니다. 지금 가입하여 다양한 활동에 참여하고,
              함께 건강한 커뮤니티를 만들어 나가세요!
            </p>
          </section>

          <section className="intro-section">
            <h2>새로운 클럽 운영 문의</h2>
            <p>
              CrewWITH 내 새로운 클럽을 만드실 분은 인스타그램 아이디{" "}
              <a
                href="https://www.instagram.com/run.sub3"
                target="_blank"
                rel="noopener noreferrer"
              >
                @run.sub3
              </a>
              로 DM 보내주세요.
            </p>
          </section>
        </div>
        <img
          key="welcome2_image"
          className="img"
          alt="welcome"
          src={welcome2}
        />
        <img
          key="welcome3_image"
          className="img"
          alt="welcome"
          src={welcome3}
        />
      </Paper>
    </div>
  );
}

export default Welcome;
