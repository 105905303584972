import React from "react";
import { Typography } from "@mui/material";
import TopAppBar from "./Main/TopAppBar";

function NotFound() {
  return (
    <div>
      <TopAppBar />
      <Typography sx={{ fontSize: 30, margin: 1 }}>Page not found</Typography>
      <Typography sx={{ fontSize: 18, margin: 1 }}>404 Error</Typography>
    </div>
  );
}

export default NotFound;
