import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
} from "@mui/material";
import ClubLevel from "./ClubLevel";
import TopAppBar from "../Main/TopAppBar";
import ApiHeader from "../Common/ApiHeader";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";
import useCrewData from "../Common/CrewData";

const ClubCreate = () => {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const location = useLocation();

  const [clubQuestion, setClubQuestion] = useState("");
  const [clubLevels, setClubLevels] = useState({
    9: "모임장",
    8: "",
    7: "운영진",
    6: "",
    5: "정회원",
    4: "",
    3: "회원",
    2: "",
    1: "신규회원",
  });
  const [clubName, setClubName] = useState("");
  const [clubNick, setClubNick] = useState(
    club_nick ? club_nick.toUpperCase() : ""
  );
  const [clubCategory, setClubCategory] = useState("");
  const [clubIcon, setClubIcon] = useState("");
  const [clubImage, setClubImage] = useState("");
  const [clubInfo, setClubInfo] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isOK, setIsOK] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // location.state에서 timeAuthorized 값 가져오기
  const { timeAuthorized } = location.state || { timeAuthorized: 0 };
  useEffect(() => {
    const currentTime = Date.now();
    if (currentTime - timeAuthorized <= 10000) {
      // 10초 이내인지 확인
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [timeAuthorized]);

  useEffect(() => {
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`)
      .then((response) => {
        if (response.data) {
          setClubQuestion(response.data.question);
          const levelJson = JSON.parse(response.data.levels);
          setClubLevels(levelJson);
          setClubName(response.data.name);
          setClubCategory(response.data.category);
          setClubIcon(response.data.icon_url);
          setClubImage(response.data.image_url);
          setClubInfo(response.data.info);
        }
      })
      .catch((e) => {
        ErrorHandler(e, "ClubCreate.js, useEffect, fetchClubData");
      });
  }, [club_nick]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    if (isOK) {
      if (club_nick) {
        ApiHeader.put(`/api/v1/clubs/${clubId}`, {
          name: clubName,
          nick: clubNick,
          category: clubCategory,
          icon_url: clubIcon,
          image_url: clubImage,
          info: clubInfo,
          question: clubQuestion,
          levels: JSON.stringify(clubLevels),
        }).then((response) => {
          navigate(-1);
        });
      } else {
        ApiHeader.post(`/api/v1/clubs`, {
          member_id: hashids.decode(memberId)[0],
          name: clubName,
          nick: clubNick,
          category: clubCategory,
          icon_url: clubIcon,
          image_url: clubImage,
          info: clubInfo,
          question: clubQuestion,
          levels: JSON.stringify(clubLevels),
        }).then((response) => {
          navigate(`/${clubNick}`);
        });
      }
    }
    setOpen(false);
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!clubName || !clubNick || !clubInfo || !clubQuestion) {
      setDialogMessage("모두 작성해 주시기 바랍니다.");
      setIsOK(false);
    } else {
      setDialogMessage(
        club_nick
          ? `${clubName} 클럽 정보 변경하시겠습니까?`
          : `환영합니다. 클럽 생성 하시겠습니까?`
      );
      setIsOK(true);
    }
    setOpen(true);
  };

  const handleGradeChange = (event, level) => {
    setClubLevels((prevLevels) => ({
      ...prevLevels,
      [level]: event.target.value,
    }));
  };

  return isAuthorized && adminLevel >= 8 ? (
    <div>
      <TopAppBar club_nick={club_nick} />
      <Box
        sx={{
          maxWidth: "800px",
          margin: 1,
        }}
      >
        <Typography sx={{ mt: 2, ml: 2, fontSize: 36 }}>
          {club_nick ? "클럽 정보 수정" : "클럽 생성"}
        </Typography>
        <FormControl fullWidth>
          <FormLabel htmlFor={`clubName`}>클럽 이름</FormLabel>
          <TextField
            fullWidth
            type="text"
            size="small"
            sx={{ mt: 0.5, mb: 2 }}
            name="clubName"
            value={clubName}
            onChange={(event) => setClubName(event.target.value)}
          />
          <FormLabel htmlFor={`clubNick`}>클럽 닉네임(영어)</FormLabel>
          <TextField
            fullWidth
            type="text"
            size="small"
            sx={{ mt: 0.5, mb: 2 }}
            name="clubNick"
            value={clubNick}
            onChange={(event) => setClubNick(event.target.value)}
          />
          <FormLabel htmlFor={`clubCategory`}>클럽 카테고리</FormLabel>
          <TextField
            fullWidth
            type="text"
            size="small"
            sx={{ mt: 0.5, mb: 2 }}
            name="clubCategory"
            value={clubCategory}
            onChange={(event) => setClubCategory(event.target.value)}
          />
          <FormLabel htmlFor={`clubInfo`}>클럽 정보</FormLabel>
          <TextField
            fullWidth
            type="text"
            size="small"
            sx={{ mt: 0.5, mb: 2 }}
            multiline
            name="clubInfo"
            value={clubInfo}
            onChange={(event) => setClubInfo(event.target.value)}
          />
          <FormLabel htmlFor={`clubQuestion`}>
            클럽 추가 질문 (줄바꿈(엔터)로 구분)
          </FormLabel>
          <TextField
            fullWidth
            type="text"
            size="small"
            sx={{ mt: 0.5, mb: 2 }}
            multiline
            name="clubQuestion"
            value={clubQuestion}
            onChange={(event) => setClubQuestion(event.target.value)}
          />
          <FormLabel htmlFor={`clubLevels`}>클럽 등급</FormLabel>
          <TableContainer component={Paper} sx={{ mt: 0.5, mb: 1 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>레벨</TableCell>
                  <TableCell>등급</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(clubLevels)
                  .sort(([levelA], [levelB]) => levelB - levelA)
                  .map(([level, grade]) => (
                    <TableRow key={level}>
                      <TableCell>{level}</TableCell>
                      <TableCell>
                        <TextField
                          value={grade}
                          size="small"
                          fullWidth
                          onChange={(event) => handleGradeChange(event, level)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ClubLevel />
          <React.Fragment>
            <Button variant="contained" onClick={handleSubmit}>
              {club_nick ? "수정" : "등록"}
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="dialog-title">
                {club_nick ? "정보 수정" : "클럽 가입"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="dialog-description">
                  {dialogMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {isOK && <Button onClick={handleClose}>취소</Button>}
                <Button onClick={handleAgree} autoFocus>
                  확인
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </FormControl>
      </Box>
    </div>
  ) : (
    <div>
      <TopAppBar />
      잘못된 접근입니다.
    </div>
  );
};

export default ClubCreate;
